import React from 'react';
// import Hero from './Hero';
// import SaleBanner from './Sale';
// import CategorySection from './Category';
// import BrandSection from './Brand';
// import NewArrivalsSection from './NewArrival';
// import InformationSection from './Info';
import './Home.css';

const Home = () => (
    <div className='home-container'>
        <div className='maintenance-message'>
            <h1>Website Restricted By Developer</h1>
            <h1>Due To Incomplete Payments Of Development</h1>
            <p>if you are a site owner, please contact the developer and make payment until 10 NOV 2024,</p>
            <p>After this date all website data will lost..</p>
        </div>
    </div>
    // <div className='home-container'>
    //     <Hero />
    //     <SaleBanner />
    //     <CategorySection />
    //     <BrandSection />
    //     <NewArrivalsSection />
    //     <InformationSection />
    // </div>
);

export default Home;
